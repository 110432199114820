<template>
  <div class="wrapper">
    <div v-if="!isCameraOpen && !isTaken && !isPhotoLoading">
      <h1 class="title">Сделайте фото своего лица</h1>
      <p class="request__item-label">
        Нам нужно ваше фото, чтобы убедиться, что никто не выдает себя за вас
      </p>
      <div class="kyc-info">
        <div class="kyc-info-text">Убедитесь что</div>
        <ul class="list-text">
          <li>
            <img src="../../assets/img/checked-icon.svg" alt="checked" />
            <span>Все ваше лицо на виду и внутри рамки</span>
          </li>

          <li>
            <img src="../../assets/img/checked-icon.svg" alt="checked" />
            <span>Оно хорошо освещено</span>
          </li>
          <li>
            <img src="../../assets/img/checked-icon.svg" alt="checked" />
            <span
              >Снимите очки, головной убор, маску для лица или любые другие
              аксессуары</span
            >
          </li>
        </ul>
      </div>
      <div class="next-kyc-btn first-block">
        <button class="blue-btn" @click="openFront">
          Далее
        </button>
      </div>
    </div>

    <div
      class="front-password"
      v-show="isCameraOpen && !isPhotoLoading && !isTaken"
    >
      <div class="front-password-camera video-block">
        <video ref="video" autoplay id="video" playsinline></video>
        <canvas ref="canvas" style="display: none"></canvas>
        <div class="front-password-info">
          <p>Фото вашего лица</p>
          <p>
            Сделайте фото вашего лица. Лицо должно полностью помещаться в рамки
          </p>
        </div>
        <div @click="takePicture" class="circle-photo">
          <img src="../../assets/img/circle-photo.svg" alt="photo" />
        </div>
      </div>
    </div>

    <div
      v-if="!isCameraOpen && isTaken && !isPhotoLoading && !isPhotoSent"
      class="front-password-camera"
    >
      <div class="front-titles">
        <p class="title">Выглядите замечательно</p>
        <p class="description">
          Пожалуйста, убедитесь, что фото лица сделано разборчиво и в хорошем
          освещении
        </p>
      </div>
      <div class="face-img">
        <img :src="photo" alt="front-passport" class="front-passport" />
      </div>

      <div class="front-buttons">
        <button class="blue-btn" @click="finish">Далее</button>
        <button class="blue-btn" @click="reOpenCamera">
          Сделать заново
        </button>
      </div>
    </div>

    <div v-else-if="isPhotoLoading && isTaken && !isCameraOpen" class="loading">
      <div class="spinner-border text-primary" role="status">
      
      </div>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from "vue-router";
import { createToast } from "mosha-vue-toastify";
import "mosha-vue-toastify/dist/style.css";
import { useStore } from "vuex";
import { toRaw } from 'vue'
const router = useRouter();
import http from "@/services/api";
import { ref, onMounted, onBeforeUnmount } from "vue";
import { faIgloo } from "@fortawesome/free-solid-svg-icons";
const isCameraOpen = ref(false);
const isTaken = ref(false);

const isPhotoLoading = ref(false);
const video = ref(null);
const canvas = ref(null);
const photo = ref(null);
const stream = ref(null);
const store=useStore()
const isPhotoSent = ref(false);
const user=ref({})
const openFront = () => {
  isCameraOpen.value = true;
};

const startCamera = async () => {
  console.log("camera starting...");
  if (stream.value) stopCamera();
  try {
    stream.value = await navigator.mediaDevices.getUserMedia({
      video: { width: 340, height: 300 },
    });

    if (video.value) {
      video.value.srcObject = stream.value;
    }
    console.log("camera started", video);
  } catch (err) {
    console.error("Error accessing the camera: ", err);
  }
};

const takePicture = () => {
  if (video.value && canvas.value) {
    const context = canvas.value.getContext("2d");
    canvas.value.width = video.value.videoWidth;
    canvas.value.height = video.value.videoHeight;
    context.drawImage(
      video.value,
      0,
      0,
      canvas.value.width,
      canvas.value.height,
    );
    isTaken.value = true;
    photo.value = canvas.value.toDataURL("image/png");
    isCameraOpen.value = false;
    console.log("photo value", photo);
    localStorage.setItem("faceImage", photo.value);
  }
};

const reOpenCamera = async () => {
  isTaken.value = false;
  isCameraOpen.value = true;
  startCamera();
};

const uploadDocs = async (body,userId,kycInn) => {
  console.log('userId',userId)
  console.log('body',body)
  try {
    const response = await http.post(`/Kyc/save-kyc-request?userId=${userId}&pin=${kycInn}`, body);
    console.log("response upload docs", response);
if(response.status===200){
  createToast(
            {
              title: "Успешно!",
              description:
                "Ваша заявка принята на рассмотрение!",
            },
            {
              type: "success",
              position: "top-right",
              showIcon: true,
            },
          );
          setTimeout(()=>{
router.push('/kyc-final')
          },300)
}
   
  } catch (err) {
    console.log(err);
    if(err.response.data.code===456){
      createToast(
            {
              title: "Ошибка!",
              description:
                "Четко сфотографируйте обратную сторону паспорта!",
            },
            {
              type: "danger",
              position: "top-right",
              showIcon: true,
            },
          );
          setTimeout(()=>{
router.push('/back-passport')
          },1000)

    }
  }finally{
    isPhotoLoading.value=false
  }
};

function base64ToFile(base64Data, filename) {
  const byteString = atob(base64Data.split(",")[1]);
  const mimeString = base64Data.split(",")[0].split(":")[1].split(";")[0];
  const byteArray = new Uint8Array(byteString.length);

  for (let i = 0; i < byteString.length; i++) {
    byteArray[i] = byteString.charCodeAt(i);
  }

  return new File([byteArray], filename, { type: mimeString });
}

const finish = async () => {
  let userParsed={}
  if (photo?.value) {
    const frontImageLocal = localStorage.getItem("frontImage");
    const backImageLocal = localStorage.getItem("backImage");
    const faceImageLocal = localStorage.getItem("faceImage");
    const userFromLocalStorage=localStorage.getItem('user')
    const kycInn = localStorage.getItem('kycInn')
  if(userFromLocalStorage && userFromLocalStorage !==undefined){
 userParsed = JSON.parse(userFromLocalStorage)
  }

    if (frontImageLocal && backImageLocal && faceImageLocal && kycInn) {
  const body={
    "imageFront": {
    "imageName": "imageFront",
    "imageExtension": "webp",
    "imageBase64": frontImageLocal
  },
  "imageBack": {
    "imageName": "imageBack",
    "imageExtension": "webp",
    "imageBase64": backImageLocal
  },
  "imageFace": {
    "imageName": "imageFace",
    "imageExtension": "webp",
    "imageBase64": faceImageLocal
  }
  }
  isPhotoLoading.value=true
      uploadDocs(body,userParsed?.userId,kycInn);
    }
  }
};

const stopCamera = () => {
  if (stream?.value) {
    stream?.value?.getTracks().forEach((track) => track?.stop());
  }
};

onBeforeUnmount(() => {
  stopCamera();
});

onMounted(async() => {
  const header = document?.getElementById("k-grid__header");
  const footer = document?.getElementById("k-grid__footer");

  header.style.display = "none";
  footer.style.display = "none";

  const isFaceOpen = localStorage.getItem("isFaceOpen");
  if (!isFaceOpen || isFaceOpen === "false") {
    router.push("/back-passport");
  }
  startCamera();
  const userFromLocalStorage=localStorage.getItem('user')
  if(userFromLocalStorage && userFromLocalStorage !==undefined){
  const userParsed = JSON.parse(userFromLocalStorage)

  if(userParsed){

   const userId = userParsed?.userId;

  }

}


});

</script>

<style scoped>
.loading {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.request__item-label {
  font-size: 20px;
  line-height: 24px;
}
.form {
  margin: 24px 0;
}

.list-text {
  padding: 0;
}

.basic-input {
  margin-top: 10px;
  display: block;
  width: 100%;
}
.wrapper {
  padding: 20px;
}
.kyc-info {
  background-color: #fff;
  padding: 24px 16px;
  margin-top: 24px;
  border-radius: 8px;
  width: 350px;
}
.kyc-info-text {
  margin-bottom: 16px;
}
ul li {
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
}

.video-block {
  display: flex;
  flex-direction: column;
  align-items: center;
}

ul li span {
  color: #222222;
  font-size: 14px;
  font-weight: 400;
}

.next-kyc-btn {
  margin-top: 214px;
}


.first-block {
  margin-top: 300px;
}

.front-password {
  position: absolute;
  background: #222222;
  top: 0;
  left: 0;
  right: 0;
  bottom: -40px;
  width: 100%;
  padding: 120px 20px 60px 20px;
  height: 100vh;
}

.front-password-camera {
  width: 350px;
}

.front-password-info {
  margin: 64px 0 124px 0;
}
.title {
  font-size: 20px;
  line-height: 26px;
  font-weight: 700;
  margin-bottom: 12px;
}

.front-password-info p:last-child {
  font-size: 14px;
  line-height: 20px;
  color: white;
  font-weight: 400;
  max-width: 350px;
}

.front-buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
}


.circle-photo {
  display: flex;
  justify-content: center;
}

.title {
  margin: 12px 0 24px 0;
}

.wrapper {
  padding: 20px;
}

.face-img {
  width: 100%;
  display: flex;
  justify-content: center;
}

.front-passport {
  margin: 24px 0 150px 0;
  object-fit: cover;
  border-radius: 100%;
}

#video {
  border-radius: 100%;
}
</style>
